import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

type IsAuthedStore = {
  portalToken: string;
  setPortalToken: (token: string) => void;
  signOut: () => void;
};

const useIsAuthedStore = createStoreWithPersistAndSubscribe<IsAuthedStore>(
  (set) => ({
    portalToken: '',
    setPortalToken: (token: string) =>
      set(
        (state) => {
          state.portalToken = token;
        },
        false,
        'SET_PORTAL_TOKEN'
      ),
    signOut: () =>
      set(
        (state) => {
          // should probably also clear the context store here
          state.portalToken = '';
        },
        false,
        'SIGN_OUT'
      ),
  }),
  { name: 'IsAuthedStore' },
  { name: 'IsAuthedStore', trace: true }
);

export const useIsAuthedShallowStore = createShallowStore(useIsAuthedStore);
