import { css } from '@emotion/react';
import { Link, useNavigate } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { PatientPortalApi } from '@frontend/api-patient-portal';
import { PersonHelpers } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Avatar,
  NakedButton,
  NakedUl,
  SkeletonLoader,
  Text,
  type TextProps,
  Tray,
  useModalControl,
  useVerticalSlideModal,
  VerticalSlideModal,
} from '@frontend/design-system';
import { useIsAuthedShallowStore } from '../is-authed';
import { useContextStore } from '../stores/context';
import { ProviderMessaging } from './provider-messaging';

export const FirstWordBoldText = (props: Omit<TextProps, 'children'> & { children: string }) => {
  const { children: text, ...restProps } = props;
  const [firstWord, restWord] = (text ?? '').split(/\s(.*)/s);

  return (
    <Text
      as='h3'
      css={css`
        font-size: ${theme.font.size.h3};
      `}
      {...restProps}
    >
      <strong>{firstWord}</strong> {restWord}
    </Text>
  );
};

export const PageHeader = () => {
  const { t } = useTranslation('patientPortal');
  const { modalProps: verticalModalProps, triggerProps: verticalTriggerProps, setOpen } = useVerticalSlideModal();
  const { modalProps, triggerProps } = useModalControl();
  const navigate = useNavigate();
  const { signOut } = useIsAuthedShallowStore('signOut');
  const { orgId, personId, clear } = useContextStore('orgId', 'personId', 'clear');
  const personQuery = useQuery({
    queryKey: ['person', personId, orgId],
    enabled: !!personId && !!orgId,
    retry: false,
    queryFn: () => PatientPortalApi.getPersonById({ personId, orgId }),
  });
  const person = personQuery.data?.person;

  return (
    <>
      <header
        css={{
          padding: theme.spacing(2),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.colors.neutral10}`,
        }}
      >
        <NakedButton
          css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}
          ref={triggerProps.ref}
          onClick={modalProps.show ? modalProps.onClose : triggerProps.onClick}
        >
          <Avatar
            src=''
            size='small'
            name={PersonHelpers.getFullName({
              FirstName: person?.firstName,
              LastName: person?.lastName,
            })}
          />
          {personQuery.isLoading ? (
            <SkeletonLoader height={'2em'} width={200} />
          ) : (
            <div>
              <FirstWordBoldText as={Text} size='large'>
                {PersonHelpers.getFullName({ FirstName: person?.firstName, LastName: person?.lastName })}
              </FirstWordBoldText>
            </div>
          )}
          <Icon
            name='caret-down-small'
            css={{ transform: modalProps.show ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 250ms' }}
          />
        </NakedButton>
        <NakedButton {...verticalTriggerProps}>
          <Icon name='inbox' />
        </NakedButton>
        <Tray
          animateOrigin='top'
          mountTarget='#main-content'
          {...modalProps}
          css={{ width: '100%', height: 'min-content', maxWidth: 'unset', padding: theme.spacing(2) }}
        >
          <NakedUl
            css={css`
              a,
              button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: ${theme.spacing(2)};
                width: 100%;
              }
              a {
                color: unset;
                text-decoration: unset;
              }
            `}
          >
            <li>
              <Link to='/profile' onClick={modalProps.onClose}>
                <Text>{t('Manage Account')}</Text>
                <Icon name='caret-right-small' />
              </Link>
            </li>
            <li>
              <NakedButton
                onClick={() => {
                  signOut();
                  clear();
                  navigate({ to: '/sign-in' });
                }}
              >
                <Text>{t('Sign Out')}</Text>
                <Icon name='log-out-small' />
              </NakedButton>
            </li>
          </NakedUl>
        </Tray>
      </header>
      <VerticalSlideModal {...verticalModalProps}>
        <ProviderMessaging onClose={() => setOpen(false)} />
      </VerticalSlideModal>
    </>
  );
};
