import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useMutation } from 'react-query';
import { PatientPortalApi, PatientPortalTypes } from '@frontend/api-patient-portal';
import { getDecodedPortalToken, isTimeExpired } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextField, useFormField, Heading, Text, ContentLoader } from '@frontend/design-system';
import { useIsAuthedShallowStore } from '../../../is-authed';
import { useContextStore } from '../../../stores/context';

export default function SignIn() {
  const { setOrgId, setPersonId } = useContextStore('setOrgId', 'setPersonId');
  const [expiredLink, setExpiredLink] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);

  const orgId = queryParams.get('org_id');
  const personId = queryParams.get('person_id');
  const verifyToken = queryParams.get('token');

  useEffect(() => {
    // check token on page loaded.
    // handle logout if token is expired.
    const decodedToken = getDecodedPortalToken(verifyToken || '');
    if (!decodedToken) return;
    const isExpired = isTimeExpired(decodedToken.exp);
    if (isExpired) {
      setExpiredLink(true);
    }
  }, [verifyToken]);

  useEffect(() => {
    // set store from url params
    if (orgId) setOrgId(orgId);
    if (personId) setPersonId(personId);
  }, [orgId, personId]);

  return expiredLink ? <ExpiredLink /> : <Login />;
}

const ExpiredLink = () => {
  const { t } = useTranslation('patientPortal');
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          alignItems: 'center',
          background: 'gainsboro',
          display: 'flex',
          flexDirection: 'column',
          height: 175,
          justifyContent: 'center',
          marginBottom: theme.spacing(2),
          width: 175,
        }}
      >
        illustration
      </div>
      <Heading>{t('This link has expired.')}</Heading>
      <Text>{t('Contact your vet to request a new link.')}</Text>
    </div>
  );
};

const Login = () => {
  const { setPortalToken } = useIsAuthedShallowStore('setPortalToken', 'portalToken');
  const { t } = useTranslation('patientPortal');

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const orgId = queryParams.get('org_id');
  const personId = queryParams.get('person_id');
  const verifyToken = queryParams.get('token');
  const hasCompleteFields = personId && orgId && verifyToken;

  const tokenMutation = useMutation({
    mutationFn: (props: PatientPortalTypes.VerifyUserPayload) => PatientPortalApi.verifyUser(props),
    onSuccess: (data) => {
      if (data.token === '') {
        console.log("can't do anything w an empty string, handle the error case");
        return;
      } else {
        setPortalToken(data.token);
        navigate({ to: '/home' });
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const fieldProps = useFormField(
    {
      type: 'text',
      validateOnChange: true,
      validator: ({ value }) => {
        // only run the validator if the user has typed in a full OTP
        if (value.length !== 6) {
          return '';
        }
        if (!hasCompleteFields) {
          return t('Missing required fields, unable to verify your code.');
        }
        if (tokenMutation.isError) {
          return t('Unable to verify your code.  Please request another link.');
        } else {
          return '';
        }
      },
    },
    [tokenMutation.isError]
  );

  useEffect(() => {
    const shouldMakeRequest = fieldProps.value.length === 6;
    if (shouldMakeRequest && !hasCompleteFields) {
      return;
    }
    if (shouldMakeRequest && hasCompleteFields) {
      tokenMutation.mutate({ otp: fieldProps.value, personId, orgId, authToken: verifyToken });
    }
  }, [fieldProps.value]);

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <ContentLoader show={tokenMutation.isLoading} />
      <div
        css={css`
          margin-bottom: ${theme.spacing(4)};
          max-width: 380px;

          svg {
            width: 200px;
            height: auto;
          }
        `}
      >
        <Heading level={2}>{t('Welcome to Weave Patient Portal')}</Heading>
        <div css={{ margin: theme.spacing(3, 0) }}>
          <TextField
            name='otp'
            label=''
            placeholder={t('Enter your 6 digit code')}
            {...fieldProps}
            css={css`
              height: 48px;
              max-width: 518px;
              width: 100%;

              border-radius: ${theme.borderRadius.medium};
              :focus {
                border: 1px solid ${theme.colors.primary20};
              }
              input::placeholder {
                color: ${theme.colors.neutral60} !important;
              }
            `}
          />
        </div>
      </div>
    </div>
  );
};
