import { FC, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { getDecodedPortalToken, isTimeExpired } from '@frontend/auth-helpers';
import { useIsAuthedShallowStore } from '../is-authed';

export const AuthenticatedRoute: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { portalToken } = useIsAuthedShallowStore('portalToken');
  const navigate = useNavigate();
  const signInUri = '/sign-in';
  const decodedPortalToken = getDecodedPortalToken(portalToken);
  const isExpired = decodedPortalToken ? isTimeExpired(decodedPortalToken.exp) : false;
  const isVerified = decodedPortalToken?.verified;
  const authenticated = isVerified && !isExpired;

  useEffect(() => {
    if (!authenticated) {
      console.log(
        `Hit a private route without being authenticated. Redirecting to ${signInUri}, and setting the original URI to ${signInUri}`
      );
      navigate({ to: signInUri });
    }
  }, [authenticated]);

  return authenticated ? children : null;
};
