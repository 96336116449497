import { useHotkeys } from 'react-hotkeys-hook';
import { hasACL, CoreACLs } from '@frontend/auth-helpers';
import { getInitialParams } from '@frontend/env';
import { EnvironmentSwitcher, EnvType } from '@frontend/environment-switcher';
import { Modal, useModalControl } from '@frontend/design-system';

export const EnvironmentSwitcherModal = () => {
  const { modalProps, closeModal, openModal } = useModalControl();
  const showEnvSwitch = hasACL('weave', CoreACLs.DEVTOOLS) || import.meta.env.MODE === 'development';
  const initialParams = getInitialParams();

  useHotkeys(
    'ctrl+shift+e',
    () => {
      if (!showEnvSwitch) {
        return;
      }

      if (!modalProps.show) {
        openModal();
      } else {
        closeModal();
      }
    },
    [modalProps.show]
  );

  return (
    showEnvSwitch && (
      <Modal {...modalProps} css={{ padding: 0 }}>
        <EnvironmentSwitcher
          css={{ margin: 0 }}
          currentBaseUrl={initialParams.backendApi}
          type={(localStorage.getItem('backendEnv') as EnvType) || 'prod'}
          onSwitch={(env: EnvType) => {
            closeModal();
            setTimeout(() => {
              localStorage.setItem('backendEnv', env);
            }, 200);
          }}
        />
      </Modal>
    )
  );
};
