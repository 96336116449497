import { css } from '@emotion/react';
import { Link, useLocation } from '@tanstack/react-location';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, NakedUl, styles } from '@frontend/design-system';

const navItems = [
  {
    name: 'Home',
    to: '/home',
    Icon: <Icon name='home' />,
    color: theme.colors.indigo40,
    selectedColor: theme.colors.indigo5,
  },
  {
    name: 'Health Records',
    to: '/health-records',
    Icon: <Icon name='forms' />,
    color: theme.colors.primary40,
    selectedColor: theme.colors.primary5,
  },
];

export const Nav = () => {
  const { current } = useLocation();
  return (
    <nav
      css={css`
        @media (min-width: 1000px) {
          position: fixed;
          top: 0;
          right: 0;
          z-index: 20000;
          background: white;
          max-height: auto;
          border-radius: ${theme.borderRadius.large};
        }

        @media (min-width: 1000px) {
          position: fixed;
          top: 1px;
          right: 0;
          z-index: 20000;
          background: white;
          max-height: 49px;
          border-radius: ${theme.borderRadius.large};
        }
        background: white;
      `}
    >
      <NakedUl
        css={css`
          display: flex;
          height: 100%;
          margin-right: 16px;
          @media (max-width: 1000px) {
            display: flex;
            justify-content: space-evenly;
            margin-right: 0;
          }
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-shrink: 1;

            @media (max-width: 1000px) {
              min-width: 0;
              width: 125px;
            }
          }
          a {
            color: unset;
            text-decoration: unset;
            display: flex;
            width: 100%;
            gap: ${theme.spacing(1)};
            padding: ${theme.spacing(1)};
            @media (max-width: 1000px) {
              flex-direction: column;
              gap: ${theme.spacing(1)};
              min-width: 0;
            }
            align-items: center;
          }
        `}
      >
        {navItems.map((item, key) => {
          const isSelected = current.pathname.includes(item.to);
          const iconColor = item.color;
          return (
            <li
              key={key}
              css={[
                css`
                margin: ${theme.spacing(2)}
                  color: ${iconColor};

                  :hover {
                    background: ${item.selectedColor};
                  }
                `,
                isSelected &&
                  css`
                    background: ${item.selectedColor};
                  `,
              ]}
            >
              <Link to={item.to}>
                <span
                  css={css`
                    min-width: 24px;
                  `}
                >
                  {item.Icon}
                </span>
                <Text
                  css={[
                    styles.truncate,
                    css`
                      max-width: 100%;
                    `,
                    isSelected &&
                      css`
                        color: inherit;
                      `,
                  ]}
                  weight='bold'
                  size='small'
                >
                  {item.name}
                </Text>
              </Link>
            </li>
          );
        })}
      </NakedUl>
    </nav>
  );
};
