import { createBrowserHistory, ReactLocation } from '@tanstack/react-location';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getInitialParams } from '@frontend/env';
import { useFetch } from '@frontend/fetch';
import { AlertsProvider, ThemeProvider } from '@frontend/design-system';
import { Main } from './components/main';
import { EnvironmentSwitcherModal } from './environment-switcher-modal';
import PWABadge from './PWABadge';

const history = createBrowserHistory();
const location = new ReactLocation({
  history,
});

export default function Root() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const initialParams = getInitialParams();
  const { setBaseUrl, setGlobalSkipValidation } = useFetch();

  setGlobalSkipValidation(true);
  setBaseUrl(initialParams.backendApi);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider includeEmotionTheme>
        <AlertsProvider>
          <EnvironmentSwitcherModal />
          <Main location={location} />
          <PWABadge />
        </AlertsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
