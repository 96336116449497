import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

type ContextStore = {
  orgId: string;
  personId: string;
  clear: () => void;
  setPersonId: (personId: string) => void;
  setOrgId: (orgId: string) => void;
};

const contextStore = createStoreWithPersistAndSubscribe<ContextStore>(
  (set) => ({
    orgId: '',
    personId: '',
    clear() {
      set((state) => {
        state.orgId = '';
        state.personId = '';
      });
    },
    setOrgId(orgId) {
      set((state) => {
        state.orgId = orgId;
      });
    },
    setPersonId(personId) {
      set((state) => {
        state.personId = personId;
      });
    },
  }),
  { name: 'tempContextStore' },
  { name: 'tempContextStore', trace: true }
);

export const useContextStore = createShallowStore(contextStore);
