import { useState, useEffect } from 'react';
import { Outlet } from '@tanstack/react-location';
import { useFetch } from '@frontend/fetch';
import { theme } from '@frontend/theme';
import { Nav } from './components/nav';
import { PageHeader } from './components/page-header';
import { useIsAuthedShallowStore } from './is-authed';

export const App = () => {
  const [httpReady, setHttpReady] = useState(false);
  const { setHeaders } = useFetch();
  const { portalToken } = useIsAuthedShallowStore('portalToken');

  useEffect(() => {
    if (portalToken) {
      setHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${portalToken}`,
      });
      setHttpReady(true);
    }
  }, [portalToken]);

  return !httpReady ? null : (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100dvh' }}>
      <PageHeader />
      <main
        id='main-content'
        css={{
          background: theme.colors.neutral5,
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          overflow: 'auto',
          width: '100%',
          position: 'relative',
        }}
      >
        <div css={{ maxWidth: 1600, width: '100%' }}>
          <Outlet />
        </div>
      </main>
      <footer css={{ borderTop: `1px solid ${theme.colors.neutral20}` }}>
        <Nav />
      </footer>
    </div>
  );
};
