import { useEffect } from 'react';
import { ReactLocation, Router } from '@tanstack/react-location';
import { getDecodedPortalToken, isTimeExpired } from '@frontend/auth-helpers';
// import { EnvironmentSwitcher, EnvType } from '@frontend/environment-switcher';
import { App } from '../app';
import { useIsAuthedShallowStore } from '../is-authed';
import SignIn from '../pages/public/sign-in';
import { getRoutes } from '../routes';

interface IMain {
  location: ReactLocation;
}

export const Main: React.FC<React.PropsWithChildren<IMain>> = ({ location }) => {
  const routes = getRoutes();
  const { portalToken, signOut } = useIsAuthedShallowStore('portalToken', 'signOut');
  const decodedPortalToken = getDecodedPortalToken(portalToken);
  const isExpired = decodedPortalToken ? isTimeExpired(decodedPortalToken.exp) : false;
  const isVerified = decodedPortalToken?.verified;
  const isAuthed = isVerified && !isExpired;

  useEffect(() => {
    // handle logout if token is expired
    const decodedToken = getDecodedPortalToken(portalToken);
    if (!decodedToken) return;
    const isExpired = isTimeExpired(decodedToken.exp);
    if (isExpired) {
      signOut();
    }
  }, [portalToken]);

  return (
    <Router routes={routes} location={location}>
      {isAuthed ? <App /> : <SignIn />}
    </Router>
  );
};
