import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { motion, AnimatePresence, Variants, LayoutGroup } from 'framer-motion';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Button, Avatar, useFormField, DatePickerField } from '@frontend/design-system';

interface AppointmentModalProps {
  provider: {
    name: string;
    practice: string;
    avatarUrl?: string;
    location?: string;
  };
  onClose: () => void;
  onBook: (appointment: {
    date: Date;
    time: string;
    visitType?: string;
    reason?: string;
    type: 'telehealth' | 'in-person';
  }) => void;
}

const getModalStyles = (step: 'type' | 'datetime' | 'confirmation') => css`
  width: 100%;
  max-width: ${step === 'datetime' ? '500px' : step === 'confirmation' ? '450px' : '550px'};
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.large};
  overflow: hidden;
  margin: ${theme.spacing(1)};
  position: relative;
`;

const modalOverlayStyles = css`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${theme.zIndex.modals};
`;

const headerStyles = css`
  padding: ${theme.spacing(3)};
  border-bottom: 1px solid ${theme.colors.neutral10};
`;

const contentStyles = css`
  padding: ${theme.spacing(3)};
`;

const visitTypes = [
  {
    id: 'telehealth',
    title: 'Telehealth',
    duration: '30 min',
    icon: 'video' as IconName,
  },
  {
    id: 'new-patient',
    title: 'New Patient',
    duration: '60 min',
    icon: 'user-management' as IconName,
  },
  {
    id: 'follow-up',
    title: 'Follow-up',
    duration: '30 min',
    icon: 'refresh' as IconName,
  },
  {
    id: 'consultation',
    title: 'Consultation',
    duration: '45 min',
    icon: 'document' as IconName,
  },
] satisfies VisitTypeCardProps['type'][];

const timeSlots = [
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
];

interface VisitTypeCardProps {
  type: {
    id: string;
    title: string;
    duration: string;
    icon: IconName;
  };
  isSelected: boolean;
  onClick: () => void;
}
const overlayVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const modalVariants: Variants = {
  hidden: {
    scale: 0.9,
    opacity: 0,
    y: 20,
  },
  visible: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      duration: 0.5,
      bounce: 0.3,
    },
  },
  exit: {
    scale: 0.9,
    opacity: 0,
    y: 20,
    transition: { duration: 0.2 },
  },
};

const containerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.08,
    },
  },
  exit: { opacity: 0 },
};

const itemVariants: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.1,
    },
  },
  exit: { y: 20, opacity: 0 },
};

const VisitTypeCard: React.FC<VisitTypeCardProps> = ({ type, isSelected, onClick }) => (
  <motion.div
    css={css`
      padding: 16px;
      border-radius: 12px;
      border: 1px solid ${isSelected ? theme.colors.primary50 : theme.colors.neutral20};
      background: ${isSelected ? theme.colors.primary5 : theme.colors.white};
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        border-color: ${theme.colors.primary50};
        background: ${theme.colors.primary5};
      }
    `}
    onClick={onClick}
    layout
  >
    <motion.div
      css={css`
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 8px;
      `}
      layout
    >
      <Icon name={type.icon} size={20} color={isSelected ? 'primary' : 'subdued'} />
      <Text weight='semibold' color={isSelected ? 'primary' : 'default'}>
        {type.title}
      </Text>
    </motion.div>
    <Text size='small' color='subdued'>
      Duration: {type.duration}
    </Text>
  </motion.div>
);

const TimeSlotGrid: React.FC<{
  selectedTime: string | null;
  onTimeSelect: (time: string) => void;
}> = ({ selectedTime, onTimeSelect }) => (
  <motion.div
    variants={containerVariants}
    initial='hidden'
    animate='visible'
    exit='exit'
    css={css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;
      margin-top: 12px;
    `}
  >
    {timeSlots.map((time, index) => (
      <motion.button
        key={time}
        variants={itemVariants}
        custom={index}
        css={css`
          padding: 8px;
          border: 1px solid ${selectedTime === time ? theme.colors.primary50 : theme.colors.neutral20};
          border-radius: 8px;
          background: ${selectedTime === time ? theme.colors.primary5 : theme.colors.white};
          cursor: pointer;
          &:hover {
            border-color: ${theme.colors.primary50};
            background: ${theme.colors.primary5};
          }
        `}
        onClick={() => onTimeSelect(time)}
        whileHover={{
          scale: 1.05,
          transition: { type: 'spring', bounce: 0.4 },
        }}
        whileTap={{ scale: 0.95 }}
        layout
      >
        <Text size='small' color={selectedTime === time ? 'primary' : 'default'}>
          {time}
        </Text>
      </motion.button>
    ))}
  </motion.div>
);

const ConfirmationScreen: React.FC<{
  provider: AppointmentModalProps['provider'];
  selectedTime: string;
  selectedDate: Date;
  visitType: string;
  onBack: () => void;
  onConfirm: () => void;
}> = ({ provider, selectedTime, selectedDate, visitType, onBack, onConfirm }) => (
  <motion.div
    layout
    initial='hidden'
    animate='visible'
    exit='exit'
    variants={{
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          type: 'spring',
          duration: 0.6,
          bounce: 0.3,
        },
      },
      exit: { opacity: 0 },
    }}
    css={css`
      text-align: center;
      padding: 24px;
    `}
  >
    <motion.div
      layout
      initial={{ scale: 0 }}
      animate={{
        scale: 1,
        transition: {
          type: 'spring',
          duration: 0.8,
          bounce: 0.5,
        },
      }}
      css={css`
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      <Icon name='calendar-confirmed' size={48} color='success' />
    </motion.div>

    <motion.div layout variants={containerVariants}>
      <motion.div layout variants={itemVariants}>
        <Text size='large' weight='semibold'>
          Confirm Your Appointment
        </Text>
      </motion.div>

      <motion.div
        layout
        variants={itemVariants}
        css={css`
          margin: 24px 0;
        `}
      >
        <Text weight='semibold'>{provider.name}</Text>
        <Text color='subdued'>{provider.practice}</Text>
        <Text
          css={css`
            margin-top: 8px;
          `}
        >
          {selectedTime} • {selectedDate.toLocaleDateString()}
        </Text>
        <Text color='subdued'>{visitTypes.find((t) => t.id === visitType)?.title}</Text>
      </motion.div>

      <motion.div
        layout
        variants={itemVariants}
        css={css`
          display: flex;
          gap: 12px;
        `}
      >
        <Button
          variant='secondary'
          size='large'
          onClick={onBack}
          css={css`
            flex: 1;
          `}
        >
          Back
        </Button>
        <Button
          variant='primary'
          size='large'
          onClick={onConfirm}
          css={css`
            flex: 1;
          `}
        >
          Confirm
        </Button>
      </motion.div>
    </motion.div>
  </motion.div>
);

export const UnifiedAppointmentModal: React.FC<AppointmentModalProps> = ({ provider, onClose, onBook }) => {
  const [step, setStep] = useState<'type' | 'datetime' | 'confirmation'>('type');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedVisitType, setSelectedVisitType] = useState<string | null>(null);
  const [reason, setReason] = useState('');

  const today = dayjs().format('YYYY-MM-DD');
  const datePicker = useFormField({ type: 'datePicker', today, value: dayjs().format('MM/DD/YYYY') });

  useEffect(() => {
    setSelectedDate(dayjs(datePicker.value).toDate());
  }, [datePicker.value]);

  const handleBook = () => {
    if (selectedTime && selectedVisitType) {
      const appointment = {
        date: selectedDate,
        time: selectedTime,
        visitType: selectedVisitType,
        reason: reason.trim(),
        type: selectedVisitType === 'telehealth' ? 'telehealth' : 'in-person',
      } as const;

      onBook(appointment);
    }
  };

  return (
    <AnimatePresence>
      <motion.div css={modalOverlayStyles} variants={overlayVariants} initial='hidden' animate='visible' exit='exit'>
        <LayoutGroup>
          <motion.div
            layout
            css={getModalStyles(step)}
            variants={modalVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
            transition={{
              layout: { type: 'spring', bounce: 0.2, duration: 0.6 },
            }}
          >
            {step !== 'confirmation' && (
              <motion.div
                layout
                css={headerStyles}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <motion.div
                  layout
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <Text size='large' weight='semibold'>
                    {step === 'type' ? 'Select Visit Type' : 'Schedule Appointment'}
                  </Text>
                  <Button variant='secondary' size='small' iconName='x' onClick={onClose} />
                </motion.div>
                <motion.div
                  layout
                  css={css`
                    display: flex;
                    gap: 12px;
                    margin-top: 16px;
                    align-items: center;
                  `}
                >
                  <Avatar name={provider.name} size='medium' />
                  <div>
                    <Text weight='semibold'>{provider.name}</Text>
                    <Text size='small' color='subdued'>
                      {provider.practice}
                    </Text>
                    {provider.location && (
                      <div
                        css={css`
                          display: flex;
                          gap: 4px;
                          align-items: center;
                          margin-top: 4px;
                        `}
                      >
                        <Icon name='location' size={14} color='subdued' />
                        <Text size='small' color='subdued'>
                          {provider.location}
                        </Text>
                      </div>
                    )}
                  </div>
                </motion.div>
              </motion.div>
            )}

            <AnimatePresence mode='wait'>
              {step === 'type' && (
                <motion.div
                  layout
                  css={contentStyles}
                  variants={containerVariants}
                  initial='hidden'
                  animate='visible'
                  exit='exit'
                >
                  <motion.div
                    layout
                    variants={itemVariants}
                    css={css`
                      margin-bottom: 24px;
                    `}
                  >
                    <Text weight='semibold'>Select Visit Type</Text>
                    <div
                      css={css`
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 12px;
                        margin-top: 12px;
                      `}
                    >
                      {visitTypes.map((type) => (
                        <VisitTypeCard
                          key={type.id}
                          type={type}
                          isSelected={selectedVisitType === type.id}
                          onClick={() => setSelectedVisitType(type.id)}
                        />
                      ))}
                    </div>
                  </motion.div>

                  <div>
                    <Text weight='semibold'>Reason for Visit</Text>
                    <textarea
                      css={css`
                        width: 100%;
                        padding: 12px;
                        border: 1px solid ${theme.colors.neutral20};
                        border-radius: 8px;
                        margin-top: 8px;
                        font-family: inherit;
                        resize: vertical;
                        min-height: 80px;

                        &:focus {
                          outline: none;
                          border-color: ${theme.colors.primary50};
                          box-shadow: 0 0 0 2px ${theme.colors.primary20};
                        }
                      `}
                      placeholder='Please briefly describe the reason for your visit...'
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>

                  <Button
                    css={css`
                      width: 100%;
                      margin-top: 24px;
                    `}
                    variant='primary'
                    size='large'
                    disabled={!selectedVisitType || !reason.trim()}
                    onClick={() => setStep('datetime')}
                  >
                    Next
                  </Button>
                </motion.div>
              )}

              {step === 'datetime' && (
                <motion.div
                  layout
                  css={css`
                    ${contentStyles}
                  `}
                  variants={containerVariants}
                  initial='hidden'
                  animate='visible'
                  exit='exit'
                >
                  <motion.div layout variants={itemVariants}>
                    <Text weight='semibold'>Select Date</Text>
                    <motion.div
                      layout
                      css={css`
                        border: 1px solid ${theme.colors.neutral10};
                        border-radius: 12px;
                        padding: 16px;
                        margin-top: 12px;
                      `}
                    >
                      <DatePickerField label='Select Date' {...datePicker} name='date-picker' />
                    </motion.div>
                  </motion.div>

                  <motion.div layout variants={itemVariants}>
                    <Text weight='semibold'>Select Time</Text>
                    <TimeSlotGrid selectedTime={selectedTime} onTimeSelect={setSelectedTime} />
                  </motion.div>

                  <motion.div
                    layout
                    variants={itemVariants}
                    css={css`
                      grid-column: span 2;
                      display: flex;
                      gap: 12px;
                      margin-top: 24px;
                    `}
                  >
                    <Button
                      variant='secondary'
                      size='large'
                      onClick={() => setStep('type')}
                      css={css`
                        flex: 1;
                      `}
                    >
                      Back
                    </Button>
                    <Button
                      variant='primary'
                      size='large'
                      disabled={!selectedTime}
                      onClick={() => setStep('confirmation')}
                      css={css`
                        flex: 1;
                      `}
                    >
                      Next
                    </Button>
                  </motion.div>
                </motion.div>
              )}

              {step === 'confirmation' && selectedTime && (
                <ConfirmationScreen
                  provider={provider}
                  selectedTime={selectedTime}
                  selectedDate={selectedDate}
                  visitType={selectedVisitType!}
                  onBack={() => setStep('datetime')}
                  onConfirm={handleBook}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </LayoutGroup>
      </motion.div>
    </AnimatePresence>
  );
};
